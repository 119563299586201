#root {
  background-color: #000;
  position: absolute;
  min-height: 400px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mapContainer,
.mapContainer canvas {
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#logo {
  position: absolute;
  right: 20px;
  top: 30px;
  z-index: 3;
}

.vf-button {
  position: absolute;
  left: 20px;
  padding: 10px;
  color: #2071b5;
  background-color: #ffda00;
  border: 1px solid #2071b5;
  border-radius: 5px;
  z-index: 3;
}

.vf-button:hover {
  cursor: pointer;
}

div[role="tooltip"] {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  pointer-events: none;
}
#popper-box {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
}
#popper-close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
}
#popper-close-icon:hover {
  cursor: pointer;
}

#alert {
  position: absolute;
  z-index: 4;
  bottom: 10px;
  left: 20px;
  margin-right: 80px;
  z-index: 502;
}

.maplibregl-ctrl-bottom-right {
  z-index: 1300 !important;
}

.hidden-map{
  z-index: 0;
  overflow: hidden;
  height: 0px;
  width: 0px;
}